<template>
    <div>
        <div>
            <slot name="title"></slot>
        </div>
        <div class="d-flex flex-wrap flex-md-row flex-column-reverse">
            <div
                v-for="(value, key) in options"
                :key="key"
                class="col-12 col-md-6 mt-1"
            >
                <vs-radio
                    v-model="option"
                    :val="key"
                    :warn="key.includes('nequi')"
                    :danger="key.includes('daviplata')"
                    :success="key.includes('cash')"
                    @input="onInput"
                >
                    <span>
                        {{ value }}
                    </span>
                </vs-radio>
            </div>
        </div>
    </div>
</template>

<script>
import {
    paymentMethodsAbbreviation,
    paymentMethodsOutput,
    paymentMethodsStore
} from "@/common/fields/payment-methods";

export default {
    name: "PaymentMethodsChecker",
    components: {},
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "cashonpickup",
            type: String,
            required: false
        },
        mode: {
            default: () => "sales",
            type: String,
            required: false
        }
        // enabled: {
        //     default: () => true,
        //     type: Boolean,
        //     required: false
        // }
    },
    emits: ["change"],
    data: () => ({
        options: paymentMethodsStore,
        option: ""
    }),
    computed: {},
    async mounted() {
        this.setOptions();
        this.setInput(this.itemCurrent);
    },
    methods: {
        setOptions() {
            const methods = {
                sales: paymentMethodsStore,
                outputs: paymentMethodsOutput,
                orders: paymentMethodsAbbreviation
            };
            const options = methods[this.mode] || paymentMethodsStore;
            this.options = options;
        },
        onInput() {
            this.$emit("change", this.option);
        },
        setInput(value) {
            this.option = value;
        }
    }
};
</script>
