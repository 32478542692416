<template>
    <div class="my-4">
        <div class="d-flex flex-wrap">
            <div class="col-lg-1 col-12 mb-2 d-flex">
                <router-link
                    to="/app/cash-outputs"
                    class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                >
                    <span>🏠</span>
                </router-link>
            </div>
            <div class="col-12 col-lg-10 mb-2 text-center">
                <h1 class="h3">
                    Registrar gastos
                    {{ selectedMerchant.merchantType | merchantInfo("type") }}
                    <strong class="text-primary">
                        {{ selectedMerchant.name }}
                    </strong>
                </h1>
            </div>
            <div class="col-12 col-lg-1 d-flex justify-content-center">
                <vs-tooltip border>
                    <vs-button
                        icon
                        border
                        :active="enableHelp"
                        @click="enableHelp = !enableHelp"
                    >
                        ℹ️
                    </vs-button>
                    <template #tooltip>
                        <small>
                            ¿ Necesitas ayuda para registrar factura ?
                        </small>
                    </template>
                </vs-tooltip>
            </div>
        </div>

        <div class="mt-3">
            <span>
                En esta sección, tienes la opción de registrar tus gastos, ya
                sea por servicios o la adquisición de inventario.
                {{
                    $enabledEcommerceOptions
                        ? "Además, puedes adjuntar archivos o imágenes como comprobantes de pago."
                        : ""
                }}
                Si deseas registrar el gasto como una deuda, simplemente activa
                la casilla correspondiente que indica "Registrar como deuda".
            </span>
        </div>

        <div v-if="enableHelp" class="d-flex flex-wrap mb-3 mt-2">
            <div class="col-10 mx-auto rounded border border-success py-4">
                <span>
                    A continuación encontrarás las instrucciones de carga de
                    inventario.
                </span>
                <ul class="list-group">
                    <li class="py-2 px-1">
                        Ingresa el concepto del gasto. Ej pago recibo luz.
                    </li>
                    <li class="py-2 px-1">
                        Ingresa el proveedor del servicio. Ej. Empresa de
                        energia
                    </li>
                    <li v-if="$enabledEcommerceOptions" class="py-2 px-1">
                        Selecciona el archivo de tu dispositivo y espera que se
                        haya cargado.
                    </li>
                    <li class="py-2 px-1">
                        Registrar un gasto con el botón "Registrar gasto".
                    </li>
                </ul>

                <span>
                    Recuerda que puedes registrar tu inventario y registrar un
                    gasto. Para realizar esta acción dirigete a:
                    <a
                        href="/app/inventories/new"
                        target="_blank"
                        rel="noopener"
                    >
                        Inventarios > Registrar
                    </a>
                </span>
            </div>
        </div>

        <div class="d-flex flex-wrap mt-3">
            <div
                class="my-2 col-12 col-lg-10 d-flex flex-lg-row flex-column justify-content-around"
            >
                <vs-checkbox v-model="needDebt" warn>
                    <span> Registrar como pago pendiente </span>
                </vs-checkbox>
                <vs-checkbox
                    v-if="$enabledEcommerceOptions"
                    v-model="needFile"
                    warn
                >
                    <span> Adjuntar comprobante de pago </span>
                </vs-checkbox>
                <vs-checkbox v-model="needDecrement" warn>
                    <span>
                        Pago hecho con dinero de caja (Te decrementará el dinero
                        en caja)
                    </span>
                </vs-checkbox>
            </div>
            <div v-if="needDecrement" class="col-12 mt-3">
                <payment-methods-checker
                    v-model="itemComponent.sourcePay"
                    mode="output"
                >
                    <template #title>
                        <span class="font-weight-bold">Método de pago</span>
                    </template>
                </payment-methods-checker>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <input-money-component
                    v-model="itemComponent.value"
                    enabled
                    border
                    :message-error="
                        !isValidValue & introValidators.value
                            ? 'Valor No puede ser $0'
                            : ''
                    "
                    label="Valor"
                    @blur="onBlurValue"
                />
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.concept"
                    border
                    shadow
                    :state="stateInputDark"
                    type="text"
                    name="concept"
                    placeholder="Pago Energia Enero"
                    label="Concepto (Opcional)"
                    class="w-100"
                >
                    <template
                        v-if="!isValidConcept & introValidators.concept"
                        #message-danger
                    >
                        El cocepto debe tener mínimo un caracter
                    </template>
                </vs-input>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.provider"
                    border
                    shadow
                    :state="stateInputDark"
                    type="text"
                    name="provider"
                    placeholder="Empresa de Gas"
                    label="Proveedor (Opcional)"
                    class="w-100"
                >
                </vs-input>
            </div>

            <div v-if="needDebt" class="col-12 col-md-6 mt-5">
                <input-money-component
                    v-model="itemComponent.valuePaid"
                    enabled
                    label="Valor Abonado"
                    :message-error="
                        !isValidValue & introValidators.value
                            ? 'Valor Abonado No puede ser $0 y debe ser menor o igual que Valor'
                            : ''
                    "
                    @blur="introValidators.valuePaid = true"
                />
            </div>

            <div v-if="needFile" class="col-12 mt-5">
                <file-uploader
                    v-model="privateFile"
                    type="billFile"
                    auto-loader
                />
            </div>
        </div>

        <div class="d-flex justify-content-center z-0 my-4">
            <vs-button @click="createCashOutputComponent">
                Registrar Gasto
            </vs-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { titleRegex } from "@/common/lib/regex";

import PaymentMethodsChecker from "@/components/orders/PaymentMethodsChecker.vue";
import FileUploader from "@/components/utils/FileUploader.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "NewCashOutput",
    components: {
        FileUploader,
        InputMoneyComponent,
        PaymentMethodsChecker
    },
    data: () => ({
        needDebt: false,
        needFile: false,
        enableHelp: false,
        needDecrement: false,
        loaderInstance: null,
        privateFile: { id: 0, name: "" },
        itemComponent: {
            sourcePay: "cash",
            provider: "",
            concept: "",
            value: 0,
            valuePaid: 0
        },
        introValidators: {
            value: false,
            valuePaid: false
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidProvider() {
            return titleRegex.test(this.provider);
        },
        isValidConcept() {
            return titleRegex.test(this.concept);
        },
        isValidValue() {
            return this.itemComponent.value > 0;
        },
        isValidValuePaid() {
            return (
                this.itemComponent.valuePaid > 0 &&
                this.itemComponent.valuePaid <= this.itemComponent.value
            );
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("cashOutput:Create", "/app/cash-outputs", true);
    },
    methods: {
        ...mapActions("cashOutput", ["createCashOutput"]),
        async createCashOutputComponent() {
            if (!this.validateData()) {
                return;
            }
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const result = await this.createCashOutput({
                    value: this.itemComponent.value,
                    valuePaid: this.itemComponent.valuePaid,
                    concept: this.itemComponent.concept || "Sin Concepto",
                    provider:
                        this.itemComponent.provider || "Provedor indefinido",
                    sourcePay: this.itemComponent.sourcePay,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType,
                    ...(this.needFile
                        ? { privateFileId: this.privateFile.id }
                        : null)
                });
                this.privateFile = { id: null, name: "Undefined" };
                this.loaderInstance.close();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();

                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Registro Exitoso",
                    text: `Gasto creado con el id ${result.id}.`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de Gastos",
                    cancelButtonText: "Nueva gasto"
                });

                if (dialogResult.isConfirmed) {
                    this.$router.push({
                        path: `/app/cash-outputs/detail/${result.id}`,
                        query: {
                            merchantId: this.selectedMerchant.merchantId,
                            merchantType: this.selectedMerchant.merchantType
                        }
                    });
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/cash-outputs/");
                }
                if (dialogResult.isDismissed) {
                    this.resetOutput();
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        onBlurValue() {
            this.introValidators.value = true;
            if (!this.introValidators.valuePaid) {
                this.itemComponent.valuePaid = +this.itemComponent.value;
            }
        },
        validateData() {
            this.introValidators = {
                value: true,
                valuePaid: true
            };

            const toComplete = [];

            if (!this.isValidValue) {
                toComplete.push("Valor");
            }
            if (!this.isValidValuePaid) {
                toComplete.push("Valor Abonado");
            }

            if (this.needFile && !this.privateFile.id) {
                toComplete.push("Archivo");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Gasto invalido",
                    text: `Revisa por favor los siguientes campos ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        resetOutput() {
            this.introValidators = {
                value: false,
                valuePaid: false
            };
            this.itemComponent = {
                provider: "",
                concept: "",
                value: 0,
                valuePaid: 0
            };
            this.privateFile = { id: 0, name: "" };
        }
    }
};
</script>
